<template>
  <v-container fluid>
    <v-row>
      <v-col cols="3" v-for="kpi in kpis" :key="kpi.name">
        <KpiCard
          :title="kpi.name"
          :value="kpi.value"
          :icon="kpi.icon"
          :value_color="kpi.color"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import KpiCard from '../../core/components/KpiCard'

export default {
  name: 'CampaignDetailSchKpis',
  components: { KpiCard },
  computed: {
    ...mapState('campaigns', ['currentItem']),
    kpis () {
      return [
        {
          name: 'ROI',
          value: this.currentItem.kpi_roi + '%',
          icon: 'fa-percent',
          color: this.getKpiColor(this.currentItem.kpi_roi)
        },
        {
          name: 'GCBM',
          value: this.currentItem.kpi_margin + '€',
          icon: 'fa-euro-sign',
          color: this.getKpiColor(this.currentItem.kpi_margin)
        },
        {
          name: 'GCBM €/L',
          value: this.currentItem.kpi_margin_per_liter + '€/L',
          icon: 'fa-euro-sign',
          color: this.getKpiColor(this.currentItem.kpi_margin_per_liter)
        },
        {
          name: 'NSV',
          value: this.currentItem.kpi_net_sales + '€',
          icon: 'fa-euro-sign'
        },
        {
          name: this.$t('campaigns.actionLitres'),
          value: this.currentItem.kpi_action_liters,
          icon: 'fa-cubes'
        },
        {
          name: this.$t('campaigns.incrementalVolume'),
          value: this.currentItem.kpi_incremental_volume,
          icon: 'fa-cubes'
        },
        {
          name: this.$t('general.incremental') + ' € GCBM',
          value: this.currentItem.kpi_incremental_euros + '€',
          icon: 'fa-euro-sign'
        },
        {
          name: this.$t('agreements.valueAddedInvestments'),
          value: this.currentItem.kpi_added_value + '€',
          icon: 'fa-euro-sign'
        },
        {
          name: this.$t('agreements.investmentDiscount'),
          value: this.currentItem.kpi_discount + '€',
          icon: 'fa-euro-sign'
        }
      ]
    }
  },
  methods: {
    getKpiColor (kpiString) {
      let color
      if (kpiString === undefined) {
        color = 'black'
      } else {
        if (kpiString.startsWith('-')) {
          color = 'red'
        } else {
          color = 'green'
        }
      }
      return color
    }
  }
}
</script>
