<template>
  <div v-if="currentCampaign.id > 0">
    <CrudTable
      v-bind="crudTableConfig"
      :storeModule="storeModule"
      :showActionsPanel="false"
      :customOptions="customOptions"
      :addItems="canAddPromosPerm"
      parentKey="campaign"
      :parentValue="String(currentCampaign.id)"
      :showAddItemsCustom="canAddPromosPerm"
      :showSearchPanel="false"
      :showSelectPanel="false"
      @addItemsEvent="newPromo"
      @messageEventResult="displayNotification($event)"
    >

      <template v-if="currentCampaign.can_edit_promos" v-slot:item.action="{item}">
        <v-icon
          v-if="canEditPromos && canDeletePromos"
          color="red"
          class="mr-3"
          @click="dialogConfirmationDeletePromo(item)"
        >fa-times
        </v-icon>
        <v-icon
          v-if="canAddPromosPerm"
          color="grey"
          @click="clonePromo(item)"
        >fa-clone</v-icon>
      </template>

      <template v-slot:[getLinkAttributes.path]="{ item }">
        <a @click="editPromo(item)">
          <u>{{ Lget(item, getLinkAttributes.name, '-') }}</u>
        </a>
      </template>
    </CrudTable>

    <v-dialog
      v-model="dialog"
      max-width="290"
      persistent
    >
      <v-card>
        <v-card-title class="text-center title">
          Advertencia
        </v-card-title>
        <v-card-text>¿Está seguro de que desea eliminar la siguiente promoción?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="deletePromo(promo)"
          >Sí, estoy seguro
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import { EventBus } from '@/apps/core/helpers/event-bus'
import { mapActions, mapState } from 'vuex'
import { BASE_URL, RESPONSE_LEVEL } from '@/variables'
import {
  createErrorNotification,
  createSuccessNotification,
  createWarningNotification
} from '@/lib/unnotificationsqueue'
import Lget from 'lodash/get'

export default {
  name: 'PromoTable',
  components: { CrudTable },
  data () {
    return {
      storeModule: 'promos',
      promo: {},
      customOptions: {
        'sortBy': ['props__cajas_producto'],
        'sortDesc': [true]
      },
      dialog: false
    }
  },
  computed: {
    ...mapState({
      crudTableConfig: function (state) { return state[this.storeModule].crudTableConfig },
      currentPromo: function (state) { return state[this.storeModule].currentItem }
    }),
    ...mapState('campaigns', { currentCampaign: 'currentItem' }),
    getLinkAttributes () {
      const linkAttributes = {}
      let value = this.crudTableConfig.headers?.length > 0 ? this.crudTableConfig.headers[0].value : ''

      linkAttributes['path'] = `item.${value}`
      linkAttributes['name'] = value
      return linkAttributes
    },
    campaignId () {
      return this.currentCampaign.id
    },
    canEditPromos: {
      get () {
        return this.currentCampaign.can_edit_promos
      }
    },
    canDeletePromos: {
      get () {
        return this.currentCampaign.has_delete_promo_perm
      }
    },
    canAddPromos: {
      get () {
        return this.currentCampaign.has_change_promo_perm
      }
    },
    canAddPromosPerm: {
      get () {
        return this.currentCampaign.has_add_promo_perm
      }
    }
  },
  methods: {
    ...mapActions({ addNotification: 'addNotification' }),
    Lget: Lget,
    displayNotification (eventOfAction) {
      if (eventOfAction.status === RESPONSE_LEVEL.SUCCESS) {
        this.addNotification(createSuccessNotification(eventOfAction.messages))
      } else if (eventOfAction.status === RESPONSE_LEVEL.WARNING) {
        this.addNotification(createWarningNotification(eventOfAction.messages))
      } else if (eventOfAction.status === RESPONSE_LEVEL.ERROR) {
        this.addNotification(createErrorNotification(eventOfAction.messages))
      } else if (eventOfAction.redirect_route_name !== undefined) {
        window.location.href = BASE_URL + eventOfAction.redirect_route_name
      }
    },
    ...mapActions('promos', ['reloadItemList', 'deleteItem', 'cloneItem']),
    ...mapActions('campaigns', ['getCampaign', 'getCampaignKpis']),
    afterCreation () {
      this.reloadItemList()
      this.reloadCampaign(this.campaignId)
    },
    dialogConfirmationDeletePromo (promo) {
      this.dialog = true
      this.promo = promo
    },
    deletePromo (item) {
      this.deleteItem(item)
        .then(
          () => {
            this.reloadItemList()
            this.reloadCampaign(this.campaignId)
          })
        .catch((error) => {
          if (error.response.status === 403) {
            this.addNotification(createWarningNotification('Lo siento, no tienes permisos para elimnar promociones'))
          }
        })
        .finally(() => {
          this.dialog = false
        })
    },
    clonePromo (item) {
      this.cloneItem(item.id)
        .then(
          () => {
            this.reloadItemList()
            this.reloadCampaign(this.campaignId)
          })
        .catch((error) => {
          console.error(error)
        })
    },
    newPromo () {
      EventBus.$emit('new-promo')
    },

    editPromo (item) {
      const routerTo = { name: 'PromoDetail', params: { id: item.id } }
      EventBus.$emit('edit-promo', routerTo)
    },

    reloadCampaign (campaignId) {
      this.getCampaign(campaignId)
        .then(() => {
          this.getCampaignKpis(campaignId)
        })
    }
  }
}
</script>
