<template>
  <div>
    <Layout :top-bar-title="title" :activeBackButton="false" :sections="sections"
            :baseLayoutInfoUrl="'/base-layout-info/'">
      <CustomLayout
        :value="currentItem"
        @input="updateItem"
        :loading="loading"
        :title="formTitle"
        :breadcrumbs="currentItem.campaign_items_breadcrumbs"
        :admin-url="currentItem.admin_url"
        :custom-view-show="showForm && !isReloadCustomViewChoices"
        :custom-view-name="'campaign_detail'"
        :custom-options="options"
        :custom-component-catalog="customComponentCatalog"
        :actions="customActions"
        :action-selected.sync="actionSelected"
        ref="custom"
      >
        <template v-slot:confirm-text>
          <v-card-text v-html="currentItem.message_confirm_delete"></v-card-text>
        </template>
      </CustomLayout>
    </Layout>
    <ConfirmDialog ref="confirm" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { createNotification } from '@/lib/unnotificationsqueue'
import sections from './defaultNavigationDrawerSections'
import { BASE_URL, RESPONSE_LEVEL } from '@/variables'
import Layout from '@/apps/core/components/Layout'
import CustomLayout from '@/apps/core/components/CustomLayout'
import { EventBus } from '@/apps/core/helpers/event-bus'
import { dateToDateRangeString } from '@/apps/core/helpers/utils'
import PromoTable from '@/apps/dido/components/tables/PromoTable'
import DetachmentsTable from '@/apps/dido/components/tables/DetachmentsTable/DetachmentsTable.vue'
import CampaignDetailSchKpis from '@/apps/dido/components/CampaignDetailSchKpis'
import CampaignDatesRangeView from '@/apps/dido/components/forms/CampaignDatesRangeView/CampaignDatesRangeView.vue'
import PnGCampaignDatesRangeView from '@/apps/dido/components/forms/PnGCampaignDatesRangeView/PnGCampaignDatesRangeView.vue'
import HierarchiesFiltersSelectorWrapper from '@/apps/core/components/HierarchiesFiltersSelectorWrapper/HierarchiesFiltersSelectorWrapper.vue'
import InternalGroupingView from './InternalGroupingView'
import NoteLinesTable from '../components/tables/NoteLinesTable/NoteLinesTable.vue'
import ProductOrdersTable from '../components/tables/ProductOrdersTable/ProductOrdersTable.vue'
import ProductsFilter from '../components/filters/ProductsFilter/ProductsFilter.vue'
import ConfirmDialog from '@/apps/core/components/ConfirmDialogAlt.vue'
import { isEqual } from 'lodash'

export default {
  name: 'CampaignDetail',
  components: { CustomLayout, Layout, ConfirmDialog },
  data () {
    return {
      promo: {},
      prefijoMatricula: null,
      actionSelected: {},
      loading: false,
      isReloadCustomViewChoices: false,
      firstIteration: true,
      sections: sections,
      campaignId: this.$route.params.id,
      bannerIdQuery: this.$route.query.id_client,
      storePromoDateQuery: this.$route.query.date,
      customComponentCatalog: {
        'PnGCampaignDatesRangeView': PnGCampaignDatesRangeView,
        'HierarchiesFiltersSelectorWrapper': HierarchiesFiltersSelectorWrapper,
        'CampaignDatesRangeView': CampaignDatesRangeView,
        'InternalGroupingView': InternalGroupingView,
        'PromoTable': PromoTable,
        'SchKpis': CampaignDetailSchKpis,
        'DetachmentsTable': DetachmentsTable,
        'NoteLinesTable': NoteLinesTable,
        'ProductOrdersTable': ProductOrdersTable,
        'ProductsFilter': ProductsFilter
      },
      options: {
        context: {
          campaignId: this.campaign_id_or_default,
          serverUrl: BASE_URL
        }
      },
      staticOldItem: null,
      countChanges: 0,
      isSaving: false
    }
  },
  computed: {
    ...mapState('campaigns', ['currentItem']),
    showForm () {
      return this.currentItem.id > 0
    },
    title () {
      return this.showForm
        ? (this.prefijoMatricula
          ? this.$t('campaigns.campaignDetailWithPrefix', { prefix: this.prefijoMatricula })
          : this.$t('campaigns.campaignDetail', { description: this.currentItem.description || '' })
        ) : this.$t('campaigns.campaignDetailLoading')
    },
    formTitle () {
      return this.isNewCampaign ? this.$t('general.newPrimary') : this.currentItem.verbose_name
    },
    isNewCampaign () {
      return this.currentItem.verbose_name === ''
    },
    customForm () {
      return this.$refs.custom && this.$refs.custom.$refs.form
    },
    customActions () {
      return [
        {
          label: this.$t('general.delete'),
          method: this.deleteForm,
          confirm: true,
          confirmOptions: {
            titleDialog: this.$t('campaigns.detailDeleteMessage'),
            confirmText: this.$t('dialogs.confirmOption'),
            confirmColor: 'red'
          },
          icon: 'fa-times',
          color: 'red',
          dark: true,
          floatEnd: true
        },
        {
          label: this.$t('customActions.saveAndContinue'),
          method: this.saveForm,
          args: { redirect: false },
          validate: true,
          icon: 'fa-save',
          color: 'primary'
        },
        {
          label: this.$t('general.save'),
          method: this.saveForm,
          args: { redirect: true },
          validate: true,
          icon: 'fa-save',
          color: 'primary'
        }
      ]
    },
    campaignHasChanged () {
      if (this.currentItem !== {} && this.staticOldItem) {
        return !isEqual(this.currentItem, this.staticOldItem)
      } else {
        return false
      }
    }
  },
  watch: {
    'currentItem.prop__location' () {
      if (!this.firstIteration) {
        this.currentItem.prop__managements = []
      }
      this.firstIteration = false
    },
    'currentItem.prop__prefijo_matricula' (value) {
      if (value) {
        this.prefijoMatricula = value
      }
    },
    currentItem: {
      deep: true,
      handler () {
        if (this.currentItem && this.currentItem.active && this.countChanges < 3) {
          this.staticOldItem = this.currentItem
          this.countChanges++
        }
      }
    }
  },
  created () {
    this.clearCurrentItem()
    this.getCampaign(this.campaignId)
      .then(() => {
        this.setQueryInCurrentItem()
        this.getCampaignKpis(this.campaignId)
      })
  },
  mounted () {
    EventBus.$on('new-promo', this.onCreatePromo)
    EventBus.$on('edit-promo', this.onEditPromo)
  },
  beforeDestroy () {
    this.dismissNotifications()
    EventBus.$off('new-promo')
    EventBus.$off('edit-promo')
  },
  methods: {
    ...mapActions('campaigns', ['updateCurrentItem', 'getCampaign', 'updateCampaign', 'deleteCampaign', 'getCampaignKpis', 'clearCurrentItem']),
    ...mapActions('promos', ['newPromo', 'reloadItemList']),
    ...mapActions({ addNotification: 'addNotification', dismissNotifications: 'dismissNotifications' }),
    campaign_id_or_default () {
      let currentId = this.$route.params.id
      if (currentId === null) {
        return -1
      } else {
        return currentId
      }
    },
    setQueryInCurrentItem () {
      if (this.bannerIdQuery === undefined || this.storePromoDateQuery === undefined) return

      let date = dateToDateRangeString(this.storePromoDateQuery)
      this.updateCurrentItem({
        ...this.currentItem,
        store_promo_date: date,
        order_date: date
      })
    },
    reloadCustomViewChoices () {
      this.isReloadCustomViewChoices = true
      this.$nextTick().then(() => {
        this.isReloadCustomViewChoices = false
      })
    },
    async saveForm (config = {}) {
      this.loading = true
      this.isSaving = true
      try {
        let campaignResponse = await this.updateCampaign(this.campaignId)
        if (campaignResponse) {
          if (config.redirect) {
            await this.$router.push({ name: 'CampaignList' })
          } else {
            location.reload()
          }
        }
      } finally {
        this.loading = false
        this.isSaving = false
      }
    },
    async deleteForm () {
      this.loading = true
      try {
        const response = await this.deleteCampaign(this.campaignId)
        if (response) await this.$router.push({ name: 'CampaignList' })
      } finally {
        this.loading = false
      }
    },
    async onCreatePromo () {
      await this.$router.push({
        name: 'PromoDetail',
        params: { id: undefined } // Id will be assigned in 'beforeRouteLeave'.
      })
    },
    async onEditPromo (item) {
      this.loading = true
      try {
        await this.$router.push(item)
      } finally {
        this.loading = true
      }
    },
    updateItem (items) {
      this.updateCurrentItem({ ...this.currentItem, ...items })
    },
    async goToNewPromo (next) {
      this.loading = true
      try {
        const response = await this.newPromo(this.campaignId)
        if (response.level === RESPONSE_LEVEL.SUCCESS) {
          next({
            name: 'PromoDetail',
            params: { id: response.id }
          })
        } else {
          this.addNotification(createNotification(response?.message, response?.level))
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  },
  async beforeRouteLeave (to, from, next) {
    if (to.name === 'PromoDetail' && to.params.id !== undefined) {
      // To avoid infinity loops.
      next()
      return
    }
    if ((this.campaignHasChanged && !this.isSaving) || this.isNewCampaign) {
      // Open confirmation dialog.
      if (await this.$refs.confirm.open(this.$t('general.warning'), this.$t('dialogs.pendingChanges'))) {
        // Select save changes.
        if (this.customForm.validate()) {
          const res = await this.updateCampaign(this.campaignId)
          if (res.status !== 200) {
            next(false)
            return
          }
        } else {
          next(false)
          this.addNotification(createNotification(this.$t('dialogs.errorFormData'), RESPONSE_LEVEL.ERROR))
          return
        }
      } else {
        // Select discard changes.
        if (this.isNewCampaign) {
          if (to.name === 'PromoDetail') {
            this.addNotification(createNotification(this.$t('campaigns.createPromoWithoutCampaign'), RESPONSE_LEVEL.ERROR))
            return
          } else {
            await this.deleteCampaign(this.campaignId)
          }
        }
      }
    }
    if (to.name === 'PromoDetail') {
      await this.goToNewPromo(next)
    } else {
      next()
    }
  }
}
</script>

<style lang="css">

</style>
